import React, { useEffect, useState } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';

import '../styles/table.css'
import ModalReturn from './ModalReturn';

const DataTables = ({data, columns, setRefresh, refresh}) => {

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
          Viendo { from } a { to } de { size } resultados
        </span>
    );

    const [showModal, setShowModal] = useState(false)
    const [date, setDate] = useState('')
    const [dataModal, setDataModal] = useState({})

    useEffect(() => {
        let now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        setDate(now.toISOString().slice(0,16))
    }, [])

    const handleReturn = (detail) => {
        setShowModal(true)
        setDataModal(detail)
    }

    const expandRow = {
        renderer: row => (
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Producto</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Precio U.</th>
                        <th scope="col">Total Día</th>
                        <th scope="col">Total Gral</th>
                        <th scope="col">Fecha Inicial</th>
                        <th scope="col">Fecha Devolución</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Devolver</th>
                    </tr>
                </thead>
                <tbody>
                {
                    row.details.map((detail, index) => {
                        let date_start = detail.date_start
                        let date_end = detail.date_end
                        if (detail.date_start) {
                            date_start = new Date(detail.date_start)
                        }
                        if (detail.date_end) {
                            date_end = new Date(detail.date_end)
                        }
                        return(
                        <tr key={index}>
                            <td>{detail.item.name}</td>
                            <td>{detail.quantity}</td>
                            <td>{detail.price}</td>
                            <td>{detail.sub_total}</td>
                            <td>{detail.grand_total}</td>
                            <td>{
                                detail.date_start?
                                
                                date_start.toLocaleDateString() + " " + date_start.toLocaleTimeString()
                                :
                                date_start
                                }
                            </td>
                            <td>{
                                detail.date_end?
                                
                                date_end.toLocaleDateString() + " " + date_end.toLocaleTimeString()
                                :
                                date_end
                                }
                            </td>
                            <td>{
                                detail.type === 'open' ?
                                <span className="badge badge-warning">Alquilado</span>
                                :
                                <span className="badge badge-success">Entregado</span>
                                }
                            </td>
                            <td>
                                {
                                    detail.type === 'open' &&
                                    <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => {handleReturn(detail)}}>
                                        <i className="fas fa-reply-all"></i>
                                    </span>
                                }
                            </td>
                        </tr>
                        )
                    })
                }
                </tbody>
            </table>
        ),
        showExpandColumn: true
    };

    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'Primera',
        prePageText: 'Atrás',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'Primera página',
        prePageTitle: 'Ant pág.',
        firstPageTitle: 'Sig pág.',
        lastPageTitle: 'Última página',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: '5', value: 5
            },
            {
                text: '10', value: 10
            },
            {
                text: '20', value: 20
            },
            {
                text: 'Todos', value: data.length
            }
        ] // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
        <>
        <ToolkitProvider 
            bootstrap4 
            keyField="id" 
            data={ data }
            columns={ columns }
            search
        >
            {
                props => 
                <>
                <SearchBar 
                    { ...props.searchProps }
                    placeholder="Buscar algo"
                />
                <BootstrapTable
                    { ...props.baseProps }
                    pagination={ paginationFactory(options) }
                    cellSpacing="0"
                    wrapperClasses="table-responsive"
                    classes="table-striped table-hover"
                    expandRow={ expandRow }
                    filter={ filterFactory() }
                />
                </>
            }
            
        </ToolkitProvider>
        {
        showModal && 
        <ModalReturn 
            setShowModal = {setShowModal}
            showModal = {showModal}
            setRefresh = {setRefresh}
            refresh = {refresh}
            date = {date}
            dataModal = {dataModal}
        />
        }
        </>
    )
}

export default DataTables
