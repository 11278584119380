import React, { useState, useEffect } from 'react'
import useForm from '../hooks/useForm'
import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import '../styles/react-select.css'
import DataTablesRentalNew from './DataTablesRentalNew';


const ModalRental = ({showModal, setShowModal, setRefresh, refresh, date, dataModal}) => {

    const handleClose = () => {
        setShowModal(false)
    }
    
    const handleSubmit = async (e) => { 
        e.preventDefault();

        const dt = new Date(dataForm.date_start)
        const newDate = `${
            dt.getFullYear().toString().padStart(4, '0')}-${
            (dt.getMonth()+1).toString().padStart(2, '0')}-${
            dt.getDate().toString().padStart(2, '0')} ${
            dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
            dt.getSeconds().toString().padStart(2, '0')}`;

        let finishDetails = []
        let object = {}
        listProducts.forEach(element => {
            object = {
                item: element.id,
                quantity: element.quantity,
                price: element.price_rental,
                sub_total: element.sub_total,
                date_start: newDate,
                grand_total: element.sub_total
            }
            finishDetails.push(object);
        });

        

        const newRental = {
            date_start: newDate,
            customer: dataForm.customer,
            observations: dataForm.observations,
            total: total,
            details: finishDetails,
            balance_due: total
        }
        
        try {
            const response = await axios.post(`items/rentals`,newRental);
            if (response.status === 200) {
                Swal.fire(
                    'Excelente!',
                    'Ha sido creado exitosamente',
                    'success'
                )
                setRefresh(!refresh)
            }
        } catch (error) {
            Swal.fire(
                'Error!',
                'Hubo un error al guardar',
                'error'
            )
        }
    }

    const [dataForm, handleChangeInput] = useForm({
        date_start: date,
        customer: '',
        observations: ''
    })

    const [total, setTotal] = useState(0)

    const [listProducts, setListProducts] = useState([])
    const columns = [
        {
            dataField: 'name',
            text: 'Artículo',
            editable: false
        },
        {
            dataField: 'price_rental',
            text: 'Valor Día'
        },
        {
            dataField: 'quantity',
            text: 'Cantidad'
        },
        {
            dataField: 'sub_total',
            text: 'SubTotal',
            editable: false
        },
        {
            dataField: 'action',
            text: 'Eliminar',
            editable: false,
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                  <>
                    <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => {handleDelete(row)}}>
                        <i className="fas fa-trash"></i>
                    </span>
                  </>
                );
            }
        }
    ]

    const handleOnSelectCustomer = (customer) => {
        handleChangeInput({target: {name: 'customer', value: customer.id}})
    }

    const handleOnSelectItem = (item) => {
        const newItem = {
            ...item, 
            quantity: 1,
            sub_total: item.price_rental
        }
        setListProducts([
            ...listProducts, newItem
        ])
    }

    const editCell = (item) => {
        const list = listProducts;
        list.forEach((product, index, array) => {
            if (product.id === item.id) {
                array[index].quantity = item.quantity;
                array[index].price_rental = item.price_rental;
                array[index].sub_total = item.price_rental * item.quantity;
            }
        });
        setListProducts(list)

        let subtotalInit = 0;
        listProducts.forEach(product => {
            subtotalInit = subtotalInit + product.sub_total
        });
        setTotal(subtotalInit)
    }

    const handleDelete = (item) => {
        setListProducts(listProducts.splice((e)=>e.id === item.id))

        let subtotalInit = 0;
        listProducts.forEach(product => {
            subtotalInit = subtotalInit + product.sub_total
        });
        setTotal(subtotalInit)
    }

    useEffect(() => {
        let subtotalInit = 0;
        listProducts.forEach(product => {
            subtotalInit = subtotalInit + product.sub_total
        });
        setTotal(subtotalInit)
    }, [listProducts])

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Generar Nuevo Alquiler</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <div className="row">
                    <div className="col-md-6">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="date_start">Fecha de Entrega</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl aria-label="date_start" aria-describedby="date_start"
                                type="datetime-local"
                                name="date_start"
                                value={dataForm.date_start}
                                onChange={handleChangeInput}
                                required
                            />
                        </InputGroup>
                    </div>
                    <div className="col-md-6 search-customer">
                        <ReactSearchAutocomplete
                            items={dataModal.customers}
                            fuseOptions={{ keys: ["document_id", "fullname"] }}
                            resultStringKeyName="fullname"
                            onSelect={handleOnSelectCustomer}
                            className="search"
                            placeholder="Busque el cliente"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 search">
                        <ReactSearchAutocomplete
                            items={dataModal.items}
                            fuseOptions={{ keys: ["name"] }}
                            resultStringKeyName="name"
                            onSelect={handleOnSelectItem}
                            className="search"
                            placeholder="Busque un artículo"
                            value
                        />
                    </div>
                </div>

                <div className="row">
                    <DataTablesRentalNew 
                        data={listProducts}
                        columns={columns}
                        editCell={editCell}
                    />
                    <h4 className="ml-3">Total Día: ${total}</h4>
                </div>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="observations">Observaciones</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="observations" aria-describedby="observations"
                        as="textarea" rows={3}
                        name="observations"
                        value={dataForm.observations}
                        onChange={handleChangeInput}
                        placeholder="Observaciones que desee agregar"
                    />
                </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ModalRental
