import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'

const TopBar = ({toggleSidebar, setToggleSidebar}) => {
    const history = useHistory();

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userName = `${user.first_name} ${user.last_name}`;

    
    const [company, setCompany] = useState("")

    const handleLogout = () => {
        sessionStorage.clear();
        //history.push('/login');
        window.location.href = '/login';
    }

    useEffect(() => {
        if (user) {
            try {
                axios.get('items/business/1').then((response) =>{
                    if (response) {
                        setCompany(response.data.data.name)
                    }
                })
            } catch (error) {
                
            }
        }
    }, [])

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3"
                onClick={() => {setToggleSidebar(!toggleSidebar)}}
            >
                <i className="fa fa-bars"></i>
            </button>

            <div
                className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <h2 className="m-0 font-weight-bold text-primary">{company}</h2>
            </div>

            <div className="d-sm-none">
                <h2 className="m-0 font-weight-bold text-primary">{company}</h2>
            </div>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow">
                    <Link className="nav-link dropdown-toggle" to="#" id="userDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userName}</span>
                        <img className="img-profile rounded-circle"
                            src="/img/undraw_profile.svg" alt="profile" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown">
                        {/* <Link className="dropdown-item" to="/">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Editar Perfil
                        </Link>
                        <div className="dropdown-divider"></div> */}
                        <button className="dropdown-item"
                            onClick={handleLogout}
                        >
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Salir
                        </button>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default TopBar
