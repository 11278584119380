import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
import useForm from '../hooks/useForm';


const Business = () => {

    const [dataForm, handleChangeInput, handleChangeForm] = useForm({
        name: '',
        nit: '',
        phone: '',
        address: '',
        city: '',
        logo: null
    })

    const [imageLogo, setImageLogo] = useState('')
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        axios.get('items/business/1?fields=*.*.*').then((response) => {
            const dataInitial = {
                name: response.data.data.name || '',
                nit: response.data.data.nit || '',
                phone: response.data.data.phone || '',
                address: response.data.data.address || '',
                city: response.data.data.city || '',
                logo: response.data.data.logo || null
            }
            handleChangeForm(dataInitial)
        })
    }, [refresh])

    const handleLogoChange = ({target}) => {
        if (target.files[0]) {
            setImageLogo(target.files[0])
        }else{
            setImageLogo('')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let id = dataForm.logo;
        try {
            if(imageLogo !== '') {
                try {
                    const form = new FormData();
                    form.append('data', imageLogo)
                    const responseImage = await axios.post('files', form, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    if (responseImage.status === 200) {
                        id = responseImage.data.data.id
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            const response = await axios.patch('/items/business/1',{
                ...dataForm,
                logo: id
            })
            if(response.status === 200) {
                Swal.fire(
                    'Excelente!',
                    'Se ha actualizado exitosamente',
                    'success'
                )
            }
            setRefresh(!refresh)
        } catch (error) {
            Swal.fire(
                'Error!',
                'Hubo un error al actualizar',
                'error'
            )
        }
    }

    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Datos de la Empresa</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Actualizar información de la Empresa</h6>
                </div>
                <Form onSubmit={handleSubmit} encType="multipart/form-data" >
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Nombre de la empresa</Form.Label>
                            <Form.Control 
                                type="text"
                                name="name"
                                value={dataForm.name}
                                onChange={handleChangeInput}
                                required
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>NIT</Form.Label>
                            <Form.Control 
                                type="text"
                                name="nit"
                                value={dataForm.nit}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control 
                                type="text"
                                name="phone"
                                value={dataForm.phone}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Dirección</Form.Label>
                            <Form.Control 
                                type="text"
                                name="address"
                                value={dataForm.address}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Ciudad</Form.Label>
                            <Form.Control 
                                type="text"
                                name="city"
                                value={dataForm.city}
                                onChange={handleChangeInput}
                            />
                        </Form.Group>
                        </div>
                        <div className="col-md-6">
                        <Form.Group >
                            <Form.Label>Logo</Form.Label>
                            <input className="form-control"
                                type="file"
                                name="logo"
                                onChange={handleLogoChange}
                            />
                        </Form.Group>
                        </div>
                        {
                            dataForm.logo?.data &&
                            <div className="col-md-6">
                                <img src={dataForm.logo?.data.thumbnails[6].url} alt="Logo" className="img-fluid img-thumbnail" />
                            </div>
                        }
                        
                    </div>
                </div>
                <div className="card-footer d-flex justify-content-end">
                    <button className="btn btn-success">Actualizar</button>
                </div>
                </Form>
            </div>
        </>
    )
}

export default Business
