import axios from 'axios'

const getUserAuth = async () => {
    let response = false
    try {
        response = await axios.get('users/me')
    } catch (error) {
        response = false
    }

    if (response === false) {
        sessionStorage.clear();
        window.location.href = '/login';
    }else{
        //If login is correct, update the token
        const token = JSON.parse(sessionStorage.getItem("token"));
        let data = {
            token: token
        }
        const newResponse = await fetch(`${process.env.REACT_APP_URL_API}auth/refresh`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        const newData = await newResponse.json();
        if (newData) {
            sessionStorage.setItem('token', JSON.stringify(newData.data.token))
        }

    }
    return response;
}

export default getUserAuth
