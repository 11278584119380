import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React from 'react'
import useForm from '../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2';

const ModalCustomer = ({showModal, setShowModal, dataModal, isEdit, setRefresh, refresh}) => {

    const handleClose = () => {
        setShowModal(false)
    }

    const [dataForm, handleChangeInput] = useForm({
        fullname: dataModal.fullname,
        phone: dataModal.phone,
        address: dataModal.address,
        city: dataModal.city,
        document_id: dataModal.document_id
    })
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEdit) {
            //When is a Update
            if (dataModal.fullname === dataForm.fullname && dataModal.phone === dataForm.phone &&dataModal.address === dataForm.address && dataModal.city === dataForm.city && dataModal.document_id === dataForm.document_id) {
                Swal.fire(
                    'Oops!',
                    'No has hecho ningún cambio',
                    'warning'
                )
            }else {
                try {
                    const response = await axios.patch(`items/customers/${dataModal.id}`,dataForm);
                    if (response.status === 200) {
                        Swal.fire(
                            'Excelente!',
                            'El Cliente ha sido actualizado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar el Cliente',
                        'error'
                    )
                }
            }

        }else {
            //When is a new
            if (dataForm.fullname === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            }else { 
                try {
                    const response = await axios.post(`items/customers`,dataForm);
                    if (response.status === 200) {
                        Swal.fire(
                            'Excelente!',
                            'El Cliente ha sido creado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al crear el Cliente',
                        'error'
                    )
                }
            }
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Editar Cliente' : 'Nuevo Cliente'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="fullname">Nombres</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="fullname" aria-describedby="fullname"
                        name="fullname"
                        value={dataForm.fullname}
                        onChange={handleChangeInput}
                        placeholder="Nombres Completos"
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="document_id">Doc. Identidad</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="document_id" aria-describedby="document_id"
                        name="document_id"
                        value={dataForm.document_id}
                        onChange={handleChangeInput}
                        placeholder="Documento de Identidad"
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="phone">Teléfono</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="phone" aria-describedby="phone"
                        type="text"
                        name="phone"
                        value={dataForm.phone}
                        onChange={handleChangeInput}
                        placeholder="Teléfono"
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="address">Dirección</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="address" aria-describedby="address"
                        type="text"
                        name="address"
                        value={dataForm.address}
                        onChange={handleChangeInput}
                        placeholder="Dirección"
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="city">Ciudad</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="city" aria-describedby="city"
                        type="text"
                        name="city"
                        value={dataForm.city}
                        onChange={handleChangeInput}
                        placeholder="Ciudad de residencia"
                    />
                </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
    
}

export default ModalCustomer
