import axios from 'axios'
import React, { useEffect, useState } from 'react'
import DataTablesRentalList from './DataTablesRentalList'
import ModalRental from './ModalRental'
import { selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';


const Rental = () => {

    const [showModal, setShowModal] = useState(false)
    const [refresh, setRefresh] = useState(true)
    const [date, setDate] = useState('')
    const [dataModal, setDataModal] = useState({})
    const [rentals, setRentals] = useState([])
    const [selectOptions, setSelectOptions] = useState([{value: 'Buscar', label: 'Buscar'}])

    const selectStatus = {
        open: 'Alquilado',
        close: 'Entregado'
    }

    const columns = [
        {
            dataField: 'customer.fullname',
            text: 'Cliente',
            filter: selectFilter({
                options: selectOptions,
                placeholder: 'Seleccione un Cliente',
            })
        },
        {
            dataField: 'date_start',
            text: 'Fecha Inicial',
            filter: dateFilter({
                placeholder: 'custom placeholder',
            }),
            formatter: (cellContent, row) => {
                let date = new Date(cellContent);
                return (
                  <>
                  {
                      cellContent.toLocaleDateString() + " " + cellContent.toLocaleTimeString()
                  }
                  </>
                );
            }
        },
        {
            dataField: 'date_end',
            text: 'Fecha Devolución',
            filter: dateFilter(),
            formatter: (cellContent, row) => {
                let date = new Date(cellContent);
                return (
                  <>
                  {
                      cellContent?
                      cellContent.toLocaleDateString() + " " + cellContent.toLocaleTimeString()
                      :
                      cellContent
                  }
                  </>
                );
            }
        },
        {
            dataField: 'total',
            text: 'Total'
        },
        {
            dataField: 'balance_due',
            text: 'Deuda'
        },
        {
            dataField: 'type',
            text: 'Estado',
            filter: selectFilter({
                options: selectStatus,
                placeholder: 'Seleccione un Estado',
            }),
            formatter: (cellContent, row) => {
                return (
                  <>
                  {
                      cellContent === 'open' ?
                      <span className="badge badge-warning">Alquilado</span>
                      :
                      <span className="badge badge-success">Entregado</span>
                  }
                  </>
                );
            }
        },
        {
            dataField: 'action',
            text: 'Acciones',
            formatter: (cellContent, row) => {
                return (
                  <>
                  {
                    row.type === 'open' &&
                    <>
                    {/* <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => {handleReturnAll(row)}}>
                        <i className="fas fa-reply-all"></i>
                    </span> */}
                    <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => {handleDelete(row)}}>
                        <i className="fas fa-trash"></i>
                    </span>
                    </>
                  }
                    
                  </>
                );
            }
        }
    ]

    const handleNew = () => {
        setShowModal(true)
    }

    // const handleReturnAll = (element) => {

    // }

    const handleDelete = (element) => {
        Swal.fire({
            title: `Está seguro de eliminar esta renta?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    element.details.forEach(async(detail) => {
                        const responseOne = await axios.delete(`items/rentals_details/${detail.details.id}`);
                    });
                    
                    const response = await axios.delete(`items/rentals/${element.id}`);
                    if (response.status === 204) {
                        Swal.fire(
                            'Eliminado!',
                            'El producto ha sido eliminado',
                            'success'
                        )
                        setRefresh(!refresh);
                    }else {
                        Swal.fire(
                            'Error!',
                            'Hubo un error al eliminar',
                            'error'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }
                
            }
        })
    }

    const getRentals = async () => {
        const response = await axios.get('items/rentals?fields=*.*.*');
        return response
    }

    const getData = async () => {
        const dataCustomers = await axios.get('items/customers');
        const dataItems = await axios.get('items/items');
        return {customers: dataCustomers.data.data, items: dataItems.data.data};
    }

    useEffect(() => {
        let now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        setDate(now.toISOString().slice(0,16))

        getData().then((response) => {
            if (response) {
                setDataModal(response)
                //Set options filter customers
                let customers = []
                response.customers.forEach(customer => {
                    customers.push({
                        value: customer.fullname,
                        label: customer.fullname
                    })
                });
                setSelectOptions(customers)
            }
        })

        getRentals().then((response) => {
            if (response.status === 200) {
                const rentalData = response.data.data;
                rentalData.forEach(renta => {
                    renta.date_start = new Date(renta.date_start)
                    
                    if (renta.date_end) {
                        renta.date_end = new Date(renta.date_end)
                    }
                });
                setRentals(rentalData)
            }
        })
    }, [refresh])

    return (
        <>
        <h1 className="h3 mb-2 text-gray-800">Alquiler de equipos</h1>
        <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Gestionar rentas</h6>
                <button className="btn btn-primary" onClick={handleNew}>
                    <span className="text">Alquilar Equipo</span>
                </button>
            </div>
            <div className="card-body">
            <DataTablesRentalList 
                data={rentals} 
                columns={columns} 
                setRefresh = {setRefresh}
                refresh = {refresh}/>
            </div>
        </div>
        {
            showModal && 
            <ModalRental 
                setShowModal = {setShowModal}
                showModal = {showModal}
                setRefresh = {setRefresh}
                refresh = {refresh}
                date = {date}
                dataModal = {dataModal}
            />
        }
        </>
    )
}

export default Rental
