import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import DataTables from './DataTables'
import ModalUsers from './ModalUsers'

const Users = () => {

    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [refresh, setRefresh] = useState(true)
    const [isEdit, setIsEdit] = useState(false)

    const getData = async () => {
        return await axios.get('users')
    }

    useEffect(() => {
        getData().then((response) => {
            if (response.status === 200) {
                setData(response.data.data)
            }
        })
    }, [refresh])

    const handleEdit = (user) => {
        setIsEdit(true)
        setShowModal(true)
        setDataModal(user)
    }

    const handleDelete = (user) => {
        Swal.fire({
            title: `Está seguro de eliminar a ${user.first_name} ?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.patch(`users/${user.id}`,{
                        status: 'deleted'
                    });
                    if (response.status === 204) {
                        Swal.fire(
                            'Eliminado!',
                            'El Usuario ha sido eliminado',
                            'success'
                        )
                        setRefresh(!refresh);
                    }else {
                        Swal.fire(
                            'Error!',
                            'Hubo un error al eliminar',
                            'error'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }
                
            }
        })
    }

    const handleNew = () => {
        setIsEdit(false)
        setShowModal(true)
        setDataModal({
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        })
    }
 
    const columns = [
        {
            dataField: 'first_name',
            text: 'Nombre',
            sort: true,
            formatter: (cell, row) => {
                return <>{`${row.first_name} ${row.last_name}`}</>;
            }
        },
        {
            dataField: 'email',
            text: 'Correo',
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cell, row) => {
                return (
                    <>
                    <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => {handleEdit(row)}}>
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                    <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => {handleDelete(row)}}>
                        <i className="fas fa-trash"></i>
                    </span>
                    </>
                )
            }
        }
    ]
    return (
        <>
        <h1 className="h3 mb-2 text-gray-800">Usuarios</h1>
        <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Listado de Usuarios</h6>
                <button className="btn btn-primary" onClick={handleNew}>
                    <span className="text">Nuevo Usuario</span>
                </button>
            </div>
            <div className="card-body">
                <DataTables data={data} columns={columns} />
            </div>
        </div>
        {
            showModal && 
            <ModalUsers 
                setShowModal = {setShowModal}
                showModal = {showModal}
                dataModal = {dataModal}
                setRefresh = {setRefresh}
                refresh = {refresh}
                isEdit = {isEdit}
            />
        }
        </>
    )
}

export default Users
