import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'

const ModalUser = ({showModal, setShowModal, dataProduct, ocupations, quantity}) => {

    const handleClose = () => {
        setShowModal(false)
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Información del producto: {dataProduct.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            TOTALES::</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dataProduct.quantity}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-box-open fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-4">
                        <div className="card border-left-danger shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                            OCUPADOS</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{quantity.rentals}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-exclamation-circle fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            DISPONIBLES</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{quantity.available}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="far fa-check-circle fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="text-center">Ocupaciones</h5>
                <div className="row">
                    <div className="table-responsive m-3">
                        <table className="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Cliente</th>
                                    <th>Fecha de Inicio</th>
                                    <th>Teléfono</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ocupations.map((ocupation, index) => (
                                        <tr key={index}>
                                            <td>{ocupation.details.customer.fullname}</td>
                                            <td>{ocupation.date_start}</td>
                                            <td>{ocupation.details.customer.phone}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
    
}

export default ModalUser
