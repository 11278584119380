import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import DataTables from './DataTables'
import ModalProduct from './ModalProduct'
import ModalShowProduct from './ModalShowProduct'



const Products = () => {

    const getData = async () => {
        return await axios.get('items/items')
    }

    const [products, setProducts] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showModalShowProduct, setShowModalShowProduct] = useState(false)
    const [dataModal, setDataModal] = useState({})
    const [refresh, setRefresh] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [rentals, setRentals] = useState([])

    const columns = [
        {
            dataField: 'name',
            text: 'Artículo',
            sort: true
        },
        {
            dataField: 'price_rental',
            text: 'Precio',
            sort: true
        },
        {
            dataField: 'quantity',
            text: 'Cantidad',
            sort: true
        },
        {
            dataField: 'location',
            text: 'Ubicación',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Acciones',
            isDummyField: true,
            formatter: (cellContent, row) => {
                return (
                  <>
                    <span className="btn btn-secondary btn-circle btn-sm mr-2" onClick={() => {handleShow(row)}}>
                        <i className="fas fa-eye"></i>
                    </span>
                    <span className="btn btn-primary btn-circle btn-sm mr-2" onClick={() => {handleEdit(row)}}>
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                    <span className="btn btn-danger btn-circle btn-sm mr-2" onClick={() => {handleDelete(row)}}>
                        <i className="fas fa-trash"></i>
                    </span>
                  </>
                );
              }
        }
    ]

    const [quantity, setQuantity] = useState({
        quantity: 0,
        available: 0, 
        rentals: 0
    })
    const [ocupations, setOcupations] = useState([])

    const handleShow = async (product) => {
        const response = await axios.get(`items/rentals_details?filter[item]=${product.id}&fields=*.*.*`)
        let rentals = response.data.data;

        let rentalsQuantity = 0;
        let ocupationsData = []
        rentals.forEach(element => {
            if (element.details) {
                if (element.type === 'open') {
                    rentalsQuantity = rentalsQuantity + element.quantity;
                    ocupationsData.push(element)
                }
            }
        });
        setQuantity({
            ...quantity,
            quantity: product.quantity,
            rentals: rentalsQuantity,
            available: product.quantity - rentalsQuantity
        })
        setOcupations(ocupationsData)

        setRentals(rentals)
        setShowModalShowProduct(true)
        setDataModal(product)
    }

    const handleEdit = (product) => {
        setIsEdit(true)
        setShowModal(true)
        setDataModal(product)
    }

    const handleNew = () => {
        setIsEdit(false)
        setShowModal(true)
        setDataModal({
            name: '',
            price_rental: '',
            quantity: '',
            location: ''
        })
    }

    const handleDelete = async (product) => {
        Swal.fire({
            title: `Está seguro de eliminar el ${product.name} ?`,
            text: "Esta acción no es reversible!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Eliminarlo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.patch(`items/items/${product.id}`,{
                        status: 'deleted'
                    });
                    if (response.status === 204) {
                        Swal.fire(
                            'Eliminado!',
                            'El producto ha sido eliminado',
                            'success'
                        )
                        setRefresh(!refresh);
                    }else {
                        Swal.fire(
                            'Error!',
                            'Hubo un error al eliminar',
                            'error'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al eliminar',
                        'error'
                    )
                }
                
            }
        })
    }

    useEffect(() => {
        getData().then((response) => {
            if (response.status === 200) {
                setProducts(response.data.data)
            }
        })
    }, [refresh])


    return (
        <>
            <h1 className="h3 mb-2 text-gray-800">Productos</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">Listado de Productos</h6>
                    <button className="btn btn-primary" onClick={handleNew}>
                        <span className="text">Nuevo Producto</span>
                    </button>
                </div>
                <div className="card-body">
                    <DataTables data={products} columns={columns} />
                </div>
            </div>
            {
            showModal && 
            <ModalProduct 
                setShowModal = {setShowModal}
                showModal = {showModal}
                dataProduct = {dataModal}
                setRefresh = {setRefresh}
                refresh = {refresh}
                isEdit = {isEdit}
            />
            }
            {
            showModalShowProduct && 
            <ModalShowProduct 
                setShowModal = {setShowModalShowProduct}
                showModal = {showModalShowProduct}
                dataProduct = {dataModal}
                quantity= {quantity}
                ocupations = {ocupations}
            />
            }
        </>
    )
}

export default Products
