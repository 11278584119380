import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React from 'react'
import useForm from '../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2';

const ModalProduct = ({showModal, setShowModal, dataProduct, isEdit, setRefresh, refresh}) => {

    const handleClose = () => {
        setShowModal(false)
    }

    const [dataForm, handleChangeInput] = useForm({
        name: dataProduct.name,
        price_rental: dataProduct.price_rental,
        quantity: dataProduct.quantity,
        location: dataProduct.location
    })
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEdit) {
            //When is a Update
            if (dataProduct.name === dataForm.name && dataProduct.price_rental === dataForm.price_rental &&dataProduct.quantity === dataForm.quantity &&dataProduct.location === dataForm.location) {
                Swal.fire(
                    'Oops!',
                    'No has hecho ningún cambio',
                    'warning'
                )
            }else {
                try {
                    const response = await axios.patch(`items/items/${dataProduct.id}`,dataForm);
                    if (response.status === 200) {
                        Swal.fire(
                            'Excelente!',
                            'El producto ha sido actualizado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar el producto',
                        'error'
                    )
                }
            }

        }else {
            //When is a new
            if (dataForm.name === '' && dataForm.price_rental === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            }else { 
                try {
                    const response = await axios.post(`items/items`,dataForm);
                    if (response.status === 200) {
                        Swal.fire(
                            'Excelente!',
                            'El producto ha sido creado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al crear el producto',
                        'error'
                    )
                }
            }
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Editar Producto' : 'Nuevo Producto'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="name">Nombre</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="name" aria-describedby="name"
                        name="name"
                        value={dataForm.name}
                        onChange={handleChangeInput}
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="price_rental">Precio</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="price_rental" aria-describedby="price_rental"
                        type="number"
                        name="price_rental"
                        value={dataForm.price_rental}
                        onChange={handleChangeInput}
                        placeholder="Precio del alquiler por día"
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="quantity">Cantidad</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="quantity" aria-describedby="quantity"
                        type="number"
                        name="quantity"
                        value={dataForm.quantity}
                        onChange={handleChangeInput}
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="location">Ubicación</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="location" aria-describedby="location"
                        name="location"
                        value={dataForm.location}
                        onChange={handleChangeInput}
                    />
                </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
    
}

export default ModalProduct
