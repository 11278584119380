import React, { useState, useEffect } from 'react'
import Sidebar from '../components/navigation/Sidebar'
import TopBar from '../components/navigation/TopBar'
import Footer from '../components/navigation/Footer'
import { Link } from 'react-router-dom'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Products from '../components/Products'
import Business from '../components/Business'
import '../utils/axiosConfig'
import getUserAuth from '../utils/getUserAuth'
import Users from '../components/Users'
import Desktop from '../components/Desktop'
import Customers from '../components/Customers'
import Rental from '../components/Rental'

const Dashboard = () => {

    const [sessionVerify, setSessionVerify] = useState(true)

    useEffect(() => {
        getUserAuth()
    }, [sessionVerify])

    const [toggleSidebar, setToggleSidebar] = useState(false)
    let className = toggleSidebar ? 'sidebar-toggled' : '';

    return (
        <Router>
        <div id="page-top" className={className}>
            <div id="wrapper">
                <Sidebar 
                    toggleSidebar = {toggleSidebar}
                    setToggleSidebar = {setToggleSidebar}
                    sessionVerify = {sessionVerify}
                    setSessionVerify = {setSessionVerify}
                />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <TopBar 
                            toggleSidebar={toggleSidebar}
                            setToggleSidebar={setToggleSidebar}
                        />
                        <div className="container-fluid">
                            <Switch>
                                <Route exact path="/products" component={Products} />
                                <Route exact path="/business" component={Business} />
                                <Route exact path="/users" component={Users} />
                                <Route exact path="/customers" component={Customers} />
                                <Route exact path="/rental" component={Rental} />
                                <Route exact path="/" component={Desktop} />
                            </Switch>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <Link className="scroll-to-top rounded" to="#page-top">
                <i className="fas fa-angle-up"></i>
            </Link>
        </div>
        </Router>
    )
}

export default Dashboard
