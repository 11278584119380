import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React from 'react'
import useForm from '../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2';

const ModalUser = ({showModal, setShowModal, dataModal, isEdit, setRefresh, refresh}) => {

    const handleClose = () => {
        setShowModal(false)
    }

    const [dataForm, handleChangeInput] = useForm({
        first_name: dataModal.first_name,
        last_name: dataModal.last_name,
        email: dataModal.email,
        password: dataModal?.password || ''
    })
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        let data = {}
        if (isEdit) {
            //When is a Update
            if (dataForm.password === '') {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email
                }
            }else {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    password: dataForm.password
                }
            }
            
            if (dataModal.first_name === dataForm.first_name && dataModal.last_name === dataForm.last_name &&dataModal.email === dataForm.email) {
                Swal.fire(
                    'Oops!',
                    'No has hecho ningún cambio',
                    'warning'
                )
            }else {
                try {
                    const response = await axios.patch(`users/${dataModal.id}`,data);
                    if (response.status === 200) {
                        Swal.fire(
                            'Excelente!',
                            'El Usuario ha sido actualizado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al actualizar el usuario',
                        'error'
                    )
                }
            }

        }else {
            //When is a new
            if (dataForm.password === '') {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    password: '12345678',
                    role: 1,
                    status: 'active'
                }
            }else {
                data = {
                    first_name: dataForm.first_name,
                    last_name: dataForm.last_name,
                    email: dataForm.email,
                    password: dataForm.password,
                    role: 1,
                    status: 'active'
                }
            }

            if (dataForm.first_name === '' && dataForm.last_name === '' && dataForm.email === '' && dataForm.password === '') {
                Swal.fire(
                    'Oops!',
                    'Faltan datos por llenar',
                    'warning'
                )
            }else { 
                try {
                    const response = await axios.post(`users`,data);
                    if (response.status === 200) {
                        Swal.fire(
                            'Excelente!',
                            'El Usuario ha sido creado exitosamente',
                            'success'
                        )
                        setShowModal(false);
                        setRefresh(!refresh)
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'Hubo un error al crear el usuario',
                        'error'
                    )
                }
            }
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Editar Usuario' : 'Nuevo Usuario'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="first_name">Nombres</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="first_name" aria-describedby="first_name"
                        name="first_name"
                        value={dataForm.first_name}
                        onChange={handleChangeInput}
                        placeholder="Nombres Completos"
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="last_name">Apellidos</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="last_name" aria-describedby="last_name"
                        type="text"
                        name="last_name"
                        value={dataForm.last_name}
                        onChange={handleChangeInput}
                        placeholder="Apellidos Completos"
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="email">Email</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="email" aria-describedby="email"
                        type="email"
                        name="email"
                        value={dataForm.email}
                        onChange={handleChangeInput}
                        placeholder="Correo electrónico"
                        required
                    />
                </InputGroup>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="password">Contraseña</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="password" aria-describedby="password"
                        type="password"
                        name="password"
                        value={dataForm.password}
                        onChange={handleChangeInput}
                    />
                </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
    
}

export default ModalUser
