import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import '../styles/table.css'


const DataTablesRentalNew = ({columns, data, editCell}) => {
    return (
        <BootstrapTable
            cellSpacing="0"
            wrapperClasses="table-responsive m-3"
            classes="table-striped table-hover"
            bootstrap4 
            keyField="id" 
            data={ data }
            columns={ columns }
            cellEdit={ cellEditFactory({ 
                mode: 'click',
                afterSaveCell: (oldValue, newValue, row, column) => { editCell(row); }
            }) }
        />
    )
}

export default DataTablesRentalNew
