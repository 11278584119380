import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import useForm from '../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2';

const ModalReturn = ({showModal, setShowModal, dataModal, setRefresh, refresh, date}) => {

    const handleClose = () => {
        setShowModal(false)
    }

    const [dataForm, handleChangeInput] = useForm({
        date_end: date,
        grand_total: dataModal.grand_total,
        days: ''
    })

    const [d_start, setD_start] = useState('')

    useEffect(() => {
        let start = new Date(dataModal.date_start)
        let end = new Date(dataForm.date_end)
        let resta = end.getTime() - start.getTime()
        const days_e = Math.ceil(resta/ (1000*60*60*24))
        handleChangeInput({target:{name: 'days', value: days_e * 1}})
        handleChangeInput({target:{name: 'grand_total', value: days_e * dataModal.sub_total}})
    }, [dataForm.date_end])

    useEffect(() => {
        if (dataForm.days !== '') {
            handleChangeInput({target:{name: 'grand_total', value: dataForm.days * dataModal.sub_total}})
        }
    }, [dataForm.days])

    useEffect(() => {
        let ds_date = new Date(dataModal.date_start)
        ds_date.setMinutes(ds_date.getMinutes() - ds_date.getTimezoneOffset());
        setD_start(ds_date.toISOString().slice(0,16))
    }, [])
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const dt = new Date(dataForm.date_end)
        const newDate = `${
            dt.getFullYear().toString().padStart(4, '0')}-${
            (dt.getMonth()+1).toString().padStart(2, '0')}-${
            dt.getDate().toString().padStart(2, '0')} ${
            dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
            dt.getSeconds().toString().padStart(2, '0')}`;

        const send = {
            date_end: newDate,
            grand_total: dataForm.grand_total,
            type: 'close'
        }

        try {
            const response = await axios.patch(`items/rentals_details/${dataModal.id}`,send);
            const response2 = await axios.get(`items/rentals/${dataModal.details.id}?fields=*.*.*`)
            //Update total
            let total = 0;
            let due = 0;
            response2.data.data.details.forEach(element => {
                total = total + element.grand_total;
                if (element.type !== 'close') {
                    due = due+ element.grand_total
                }
            });

            let send2 = {
                total: total,
                balance_due: due
            }

            if (due === 0) {
                send2 = {
                    ...send2,
                    type: 'close',
                    date_end: newDate
                }
            }

            const response3 = await axios.patch(`items/rentals/${dataModal.details.id}`,send2)
            if (response.status === 200 ) {
                Swal.fire(
                    'Excelente!',
                    'Se ha procesado con éxito',
                    'success'
                )
                setShowModal(false);
                setRefresh(!refresh)
            }
        } catch (error) {
            Swal.fire(
                'Error!',
                'Hubo un error al actualizar la solicitud',
                'error'
            )
        }
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Retornar Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <InputGroup size="sm" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="grand_total">Fecha Inicial</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="grand_total" aria-describedby="grand_total"
                        type="datetime-local"
                        name="grand_total"
                        value={d_start}
                        readOnly
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="date_end">Fecha Devolución</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="date_end" aria-describedby="date_end"
                        type="datetime-local"
                        name="date_end"
                        value={dataForm.date_end}
                        onChange={handleChangeInput}
                        required
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="days">Días Cobrados</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="days" aria-describedby="days"
                        type="number"
                        name="days"
                        value={dataForm.days}
                        onChange={handleChangeInput}
                        placeholder="Días Facturados"
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="grand_total">Total a Cobrar</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl aria-label="grand_total" aria-describedby="grand_total"
                        type="number"
                        name="grand_total"
                        value={dataForm.grand_total}
                        onChange={handleChangeInput}
                        placeholder="Total General"
                        required
                    />
                </InputGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" type="submit">
                        Guardar
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
    
}

export default ModalReturn
