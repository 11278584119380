import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({toggleSidebar, setToggleSidebar, sessionVerify, setSessionVerify}) => {
    let className = toggleSidebar ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion';

    return (
        <ul className={className}
        id="accordionSidebar">
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-boxes"></i>
                </div>
                <div className="sidebar-brand-text mx-3">URAWEB INVENTARIOS</div>
            </Link>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item" onClick={() => {setSessionVerify(!sessionVerify)}}>
                <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Escritorio</span>
                </Link>
            </li>

            <li className="nav-item" onClick={() => {setSessionVerify(!sessionVerify)}}>
                <Link className="nav-link" to="/rental">
                    <i className="fas fa-fw fa-clipboard-check"></i>
                    <span>Alquiler</span>
                </Link>
            </li>

            <hr className="sidebar-divider" />

            <div className="sidebar-heading">
                Opciones
            </div>

            <li className="nav-item" onClick={() => {setSessionVerify(!sessionVerify)}}>
                <Link className="nav-link" to="/products">
                    <i className="fas fa-fw fa-box"></i>
                    <span>Productos</span>
                </Link>
            </li>

            <li className="nav-item" onClick={() => {setSessionVerify(!sessionVerify)}}>
                <Link className="nav-link" to="/customers">
                    <i className="fas fa-fw fa-user-tie"></i>
                    <span>Clientes</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link collapsed" to="/" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-paste"></i>
                    <span>Reportes</span>
                </Link>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">REPORTES:</h6>
                        <Link className="collapse-item" to="/" onClick={() => {setSessionVerify(!sessionVerify)}}>Reporte 1</Link>
                        <Link className="collapse-item" to="/" onClick={() => {setSessionVerify(!sessionVerify)}}>Reporte 2</Link>
                    </div>
                </div>
            </li>

            <hr className="sidebar-divider d-none d-md-block" />

            <div className="sidebar-heading">
                Configuraciones
            </div>

            <li className="nav-item" onClick={() => {setSessionVerify(!sessionVerify)}}>
                <Link className="nav-link" to="/business">
                    <i className="fas fa-fw fa-building"></i>
                    <span>Mi Empresa</span>
                </Link>
            </li>
            <li className="nav-item" onClick={() => {setSessionVerify(!sessionVerify)}}>
                <Link className="nav-link" to="/users">
                    <i className="fas fa-fw fa-users-cog"></i>
                    <span>Usuarios</span>
                </Link>
            </li>

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"
                    onClick={() => {setToggleSidebar(!toggleSidebar)}}
                ></button>
            </div>
        </ul>
    )
}

export default Sidebar
