import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import useForm from '../hooks/useForm'
import { useDispatch } from 'react-redux';
import { doLogin } from '../actions/ActionLogin'

const Login = () => {

    const history = useHistory();

    const [dataForm, handleChangeInput, reset] = useForm({
        email: '',
        password: ''
    });

    const [showAlert, setShowAlert] = useState(false);

    const {email, password} = dataForm;

    const dispatch = useDispatch();
    const newLogin = (data) => {dispatch(doLogin(data))}


    const handleSubmit = async (e) => {
        e.preventDefault();
        const URL = `${process.env.REACT_APP_URL_API}auth/authenticate`;
        let dataSend = {
            ...dataForm,
            mode: 'jwt'
        }
        try {
            const response = await fetch(URL, {
                method: 'POST', 
                body: JSON.stringify(dataSend),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response) {
                if (response.status === 200) {
                    setShowAlert(false);
                    const {data} = await response.json();
                    const token = data.token
                    sessionStorage.setItem('token', JSON.stringify(token))
                    sessionStorage.setItem('user', JSON.stringify(data.user))
                    //Set the state with redux
                    newLogin(true);
                    history.push('/');
                }else {
                    setShowAlert(true);
                }
            }else {
                setShowAlert(true);
            }
        } catch (error) {
            setShowAlert(true);
        }
        
        
    }

    return (
        <div className="bg-gradient-primary-login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                        
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">Bienvenido!</h1>
                                            </div>
                                            {
                                            showAlert &&
                                                <div className="alert alert-danger text-center" role="alert">
                                                    Usuario o Contraseña Incorrecta
                                                </div>
                                            }
                                            <form className="user"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"
                                                        name="email"
                                                        value= {email}
                                                        onChange={handleChangeInput}
                                                        id="exampleInputEmail" aria-describedby="emailHelp"
                                                        placeholder="Correo Electrónico"
                                                        required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password"
                                                        name="password"
                                                        value={password}
                                                        onChange={handleChangeInput}
                                                        className="form-control form-control-user"
                                                        id="exampleInputPassword" placeholder="Contraseña"
                                                        required />
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label" htmlFor="customCheck">Recuérdame</label>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary btn-user btn-block">
                                                    Iniciar Sesión
                                                </button>
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <Link className="small" to="/forgot-password">Olvidó su clave?</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
